<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
      </div>
      <div class="card-toolbar">
        <div class="me-2" v-if="permission.isPullAllowed && !isStoreInfoTradingPartner">
            <a href="javascript:void()" class="btn btn-lg btn-flex btn-info btn-active-info fw-bolder" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
              <i class="bi bi-cloud-arrow-down fs-4"></i>
              {{ordersConstants.PULL_ORDERS}}
              <span class="svg-icon svg-icon-5 m-0">
                <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-4 w-350px fs-6" data-kt-menu="true">
            <div class="menu-item px-5">
              <div class="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">{{ordersConstants.PULL_ORDER_OPTIONS}}</div>
            </div>
                <div class="menu-item px-5">
                <el-button size="large" class="menu-link px-5" :underline="false" link :loading="pullAllOrderLoading" @click.stop="pullOrders('all');"><i class="bi bi-cloud-arrow-down fs-4" ></i>&nbsp; Pull Orders From All Partners </el-button>
                
                </div>
            <template v-for="partner in tradingPartners">
                <div class="menu-item px-5" v-if="partner.store_info != null && partner.store_info != ''">
                <el-button size="large" class="menu-link px-5" :underline="false" link :loading="partner.loading ? partner.loading : false" @click.stop="pullOrders(partner.trading_partner_id);"><i class="bi bi-cloud-arrow-down fs-4" ></i>&nbsp; {{partner.user.name +'(' + partner.get_platform.platform_name + ')'}} </el-button>
                </div>
            </template>
          </div>
        </div>
        <div class="me-2" v-if="permission.isImportOrderAllowed || permission.isImportOrderHistoryAllowed">
            <a href="javascript:void()" class="btn btn-purple" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
              <i class="bi bi-file-earmark-arrow-up fs-4"></i>
              {{ordersConstants.IMPORT_ORDERS}}
              <span class="svg-icon svg-icon-5 m-0">
                <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-purple fw-bold py-4 w-250px fs-6" data-kt-menu="true">
            <div class="menu-item px-5">
              <a href="javascript:void(0);" v-if="permission.isImportOrderAllowed" class="menu-link px-5" @click.stop="$router.push({ name: 'import-orders' })"><i class="bi bi-file-earmark-arrow-up fs-4"></i>&nbsp; {{ordersConstants.IMPORT_ORDERS}}</a>
            </div>
            <div class="menu-item px-5">
              <a href="javascript:void(0);" v-if="permission.isImportOrderHistoryAllowed" class="menu-link px-5" @click.stop="$router.push({ name: 'import-orders-history' })"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ordersConstants.IMPORT_ORDER_HISTORY}}</a>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-primary" @click="$router.push({ name: 'manual-order' })"><i class="bi bi-plus-circle-fill fs-4"></i>Manual Order</button>
        <div class="me-2 d-none">
            <a href="javascript:void()" class="btn btn-purple" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
              <i class="bi bi-file-earmark-arrow-down fs-4"></i>
              {{ ordersConstants.DOWNLOAD_JOB_TICKET_BTN }}
              <span class="svg-icon svg-icon-5 m-0">
                <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-purple fw-bold py-4 w-300px fs-6" data-kt-menu="true">
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchJoBTicket('all')"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_ALL_JOB_TICKET_BTN }}</a>
            </div>
            <div class="menu-item px-5">
              <a href="javascript:void(0);" class="menu-link px-5" @click.prevent="downloadBatchJoBTicket('selected')"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ ordersConstants.DOWNLOAD_SELECTED_JOB_TICKET_BTN }}</a>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end" >
          <span class="svg-icon svg-icon-2">
            <inline-svg src="/media/icons/duotune/general/gen024.svg" />
          </span>
        </button>
        <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
          <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bolder">{{generalConstants.FILTER_COLUMNS}}</div>
          </div>
          <div class="separator border-gray-200"></div>
          <div class="px-7 py-5">
            <div class="mb-10">
              <span v-for="header in tableHeader" :key="header.key">
              <el-checkbox
                :disabled="visibleFields.length == 1 && header.visible"
                v-model="header.visible"
                v-if="header.key != 'actions'"
                class="me-2"
                inline >
                {{ header.name }}
              </el-checkbox>
              </span>
            </div>
            <div class="d-flex justify-content-end">
              <button type="submit" @click="submitFilters(visibleFields)" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" > {{generalConstants.SAVE}} </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row" >
        <div class="col-xl-12">
          <!--begin::Accordion-->
          <div class="accordion" id="kt_accordion_1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="kt_accordion_1_header_1">
                <button class="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="false" aria-controls="kt_accordion_1_body_1">
                  <h4 class="fw-bolder m-0">Filters</h4>
                </button>
              </h2>
              <div id="kt_accordion_1_body_1" class="accordion-collapse collapse" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
                <div class="accordion-body">
                  <div class="d-flex align-items-center position-relative my-1">
                    <div class="row">
                      <div class="col-md-2">
                        <input type="text" v-model="searchParams.search" v-on:keyup.enter="handleSearch()" class="form-control me-2" :placeholder="ordersConstants.SEARCH_ORDERS" />
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.orderType" :placeholder="ordersConstants.ORDER_TYPE" size="large">
                          <el-option value="">{{ordersConstants.ORDER_TYPE}}</el-option>
                          <el-option value="0" label="Manual">Manual</el-option>
                          <el-option value="1" label="Online">Online</el-option>
                          <el-option value="2" label="Offline">Offline</el-option>
                        </el-select>
                      </div>
                      <div class="col-md-2">
                        <el-select v-model="searchParams.partner" :placeholder="ordersConstants.TRADING_PARTNER" size="large">
                          <el-option value="">{{ordersConstants.TRADING_PARTNER}}</el-option>
                          <el-option v-for="partner in tradingPartners" :value="partner.trading_partner_id" :key="partner" :label="partner.user.name">
                            {{partner.user.name}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order date"
                          end-placeholder="Order date"
                          size="large"
                        />
                      </div>
                      <div class="col-md-3">
                        <el-date-picker
                          class="w-100 ml-4"
                          v-model="searchParams.searchByOrderDueDate"
                          type="daterange"
                          range-separator="To"
                          value-format="YYYY-MM-DD"
                          start-placeholder="Order Due date"
                          end-placeholder="Order Due date"
                          size="large"
                          :shortcuts="shortcuts"
                          unlink-panels
                        />
                      </div>
                      <div class="col-md-2 mt-2">
                        <el-select v-model="searchParams.searchByOrderStatus" :placeholder="ordersConstants.ORDER_STATUS" size="large">
                          <el-option value="">{{ordersConstants.ORDER_STATUS}}</el-option>
                          <el-option v-for="status in orderStatus" :value="status.type" :key="status" :label="status.title">
                            {{status.title}}
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-2 mt-2">
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.SEARCH" placement="top">
                          <button type="button" @click.prevent="handleSearch()" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
                        </el-tooltip>
                        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
                          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Accordion-->
        </div>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
        @sort="handleSortChange"
      >
        <!-- <template v-slot:cell-jobticket="{ row: data }">
          <el-checkbox size="large" @change="setDownloadJobTicket($event,data.id)" />
        </template> -->
        <template v-slot:cell-id="{ row: data }">
          <p class="mt-2 mb-0" v-if="permission.isViewAllowed"><a href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary" @click.prevent="openDetailPage(data.id)"> #{{ data.id}}</a></p>
          <p class="mt-2 mb-0" v-else>#{{ data.id}}</p>
        </template>
        <template v-slot:cell-invoice_number="{ row: data }">
           <span v-if="data.invoice_number && data.invoice_number != ''">
              {{ data.invoice_number}}
           </span>
           <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-order_type="{ row: data }">
           <span v-if="data.order_type == 0">
              Manual
           </span>
           <span v-else-if="data.order_type && data.order_type == '1'">
              Online
           </span>
           <span v-else-if="data.order_type && data.order_type == '2'">
              Offline
           </span>
           <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-partner="{ row: data }">
          <div v-if="permission.isViewAllowed"><a href="javascript:void(0)" @click.prevent="openDetailPage(data.id)">
            <el-tooltip class="box-item" effect="dark" :content="((data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name + '<br>' : '') + ((data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) ? '(' + data.trading_partner.user.name + ')' : '')" placement="top" raw-content>
              <a href="javascript:void(0)" @click.prevent="openDetailPage(data.id)">
                  <img :src="setImage(data.trading_partner.partner_image_path)" class="w-25px ms-n1 rounded-circle" :alt="(data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name : '' ">
              </a>
            </el-tooltip>
          </a>
          </div>
          <div v-else>
            <el-tooltip class="box-item" effect="dark" :content="((data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name + '<br>' : '') + ((data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) ? '(' + data.trading_partner.user.name + ')' : '')" placement="top" raw-content>
              <img :src="setImage(data.trading_partner.partner_image_path)" class="w-25px ms-n1 rounded-circle" :alt="(data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name : '' ">
            </el-tooltip>
          </div>
        </template>
        <template v-slot:cell-order_id="{ row: data }">
          <p class="mt-2 mb-0" v-if="permission.isViewAllowed"><a href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary" @click.prevent="openDetailPage(data.id)"> {{ data.order_id}}</a></p>
          <p class="mt-2 mb-0" v-else>#{{ data.order_id}}</p>
        </template>
        <template v-slot:cell-customer_details="{ row: data }">
          <span v-if="data.customer_name || data.customer_phone || data.customer_email">
            <dl class="row mb-0" v-if="data.customer_name || data.customer_phone">
                <span v-if="data.customer_name">{{data.customer_name}} <span v-if="data.customer_phone"> <a :href="`tel:${data.customer_phone}`" class="text-gray-600 text-hover-primary mb-1">({{data.customer_phone}})</a></span></span>
            </dl>
            <dl class="row mb-0" v-if="data.customer_email">
                <span><a :href="`mailto:${data.customer_email}`" class="text-gray-600 text-hover-primary mb-1">{{data.customer_email}}</a></span>
            </dl>
          </span>
          <span v-else>
              -
          </span>
        </template>
        <template v-slot:cell-order_date="{ row: data }">
          <span v-if="data.order_date">
            {{ formatDateTime(data.order_date)}}
           </span>
           <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-order_due_date="{ row: data }">
          <span v-if="data.order_due_date">
            {{ formatDate(data.order_due_date)}}
          </span>
          <span v-else>
             -
          </span>
        </template>
        <template v-slot:cell-order_products_count="{ row: data }">
          <span v-if="data.order_products_count">
              <a href="javascript:void(0)" @click="openDetailPage(data.id)"><span class="badge bg-primary">{{ data.order_products_count }}</span></a>
              <el-tooltip class="box-item" effect="dark" content="Pending Order Products to Approve. <br> <small> Click to go to pending orders </small>" placement="top" raw-content>
                <a href="javascript:void(0)" @click="redirectToPendingOrder(data.pending_order.pending_order_id)"><span v-if="data.pending_order && data.pending_order.pending_order_products_count" class="badge bg-warning ms-2">{{ data.pending_order.pending_order_products_count }}</span></a>
              </el-tooltip>
           </span>
           <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-total_amount="{ row: data }">
          <span v-if="data.order_payment && (data.order_payment.shipping_charges || data.order_payment.subtotal || data.order_payment.tax || data.order_payment.total_amount)">
            <span v-if="data.order_payment.shipping_charges">{{ordersConstants.SHIPPING_CHARGES}} : {{ formatPrice(data.order_payment.shipping_charges) }} <br>&nbsp; </span>
            <span v-if="data.order_payment.subtotal">{{ordersConstants.SUBTOTAL}} : {{ formatPrice(data.order_payment.subtotal) }} <br>&nbsp; </span>
            <span v-if="data.order_payment.tax">{{ordersConstants.TAX}} : {{ formatPrice(data.order_payment.tax) }} <br>&nbsp; </span>
            <span v-if="data.order_payment.total_amount">{{ordersConstants.TOTAL_AMOUNT}} : {{ formatPrice(data.order_payment.total_amount) }} <br>&nbsp; </span>
          </span>
          <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-payment_shipping="{ row: data }">
          <span v-if="data.shipping_method || data.shipping_type">
            <span>{{ data.shipping_method }} </span> <span>{{ data.shipping_type }}</span>
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:cell-order_status="{ row: data }">
          <span v-if="data.orderstatus && data.orderstatus.title" :class="`badge badge-light-${data.orderstatus.badge}`" >
            {{ data.orderstatus.title }}
            <span v-if="data.order_status == 'shipped' || data.order_status == 'completed'">
              <i class="bi bi-check-circle-fill text-success fs-3"></i>
            </span>
          </span>
          <span v-else>
              -
           </span>
        </template>
        <template v-slot:cell-order_watchlist="{ row: data }">

            <template v-if="permission.isOrderWatchlistAllowed">
              <el-tooltip class="box-item" v-if="data.order_watchlist != null" effect="dark" :content="ordersConstants.REMOVE_ORDER_WATCHLIST" placement="top">
              <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="addOrderWatch(data.id,'delete', tableData.findIndex((i) => i.id === data.id),data.order_watchlist.order_watchlist_id)"><i class="bi bi-stopwatch-fill"></i></a>
              </el-tooltip>
              <el-tooltip v-else class="box-item" effect="dark" :content="ordersConstants.ADD_ORDER_WATCHLIST" placement="top">
              <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="addOrderWatch(data.id,'insert',tableData.findIndex((i) => i.id === data.id))"><i class="bi bi-stopwatch"></i></a>
              </el-tooltip>
            </template>
            <template v-else>
              -
            </template>

        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isViewAllowed || permission.isViewOrderProductAllowed || permission.isDownloadJobTicketAllowed">
            <a href="javascript:void(0);" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" :id="'menuAction' + data.product_id" >Actions
              <span class="svg-icon svg-icon-5 m-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                </svg>
              </span>
            </a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-250px py-4" data-kt-menu="true">
              <div class="menu-item px-3" v-if="permission.isViewOrderProductAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="assignViewOrderProductModalId(data.id)" data-bs-toggle="modal" data-bs-target="#order_products_modal"><i class="bi bi-bag-check"></i> &nbsp; {{ ordersConstants.VIEWORDERPRODUCTS }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isViewAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="openDetailPage(data.id)"><i class="bi bi-eye"></i> &nbsp; {{ ordersConstants.VIEWORDERDETAILS }}</a>
              </div>
              <div class="menu-item px-3" v-if="permission.isDownloadOrderInvoiceAllowed">
                <a href="javascript:void(0);" class="menu-link px-3" @click.prevent="downloadOrderInvoice(data.id,data.trading_partner_id,data.customer_name)"><i class="bi bi-download"></i> &nbsp; {{ ordersConstants.DOWNLOAD_ORDER_INVOICE }}</a>
              </div>
            </div>
          </div>
          <div v-else>
            -
          </div>

        </template>
      </Datatable>
    </div>
    <ViewOrderProducts  ref="orderId"
    ></ViewOrderProducts>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatPrice, formatDate, formatText,formatDateTime } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import { ElNotification } from 'element-plus'
import { notificationFire } from "@/composable/notification.js";
import axios from "axios";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import globalConstant from "@/core/data/globalConstant.js";
import ViewOrderProducts from "@/components/modals/forms/ViewOrderProducts.vue";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useEventBus from '../../composable/useEventBus'

export default {
  name: "orders",
  components: {
    Datatable,
    ViewOrderProducts
  },
  setup() {
    const { emitEvent } = useEventBus()
    let total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const pullAllOrderLoading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const tradingPartners = ref([]);
    const orderStatus = ref([]);
    const isStoreInfoTradingPartner = ref(false);
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const orderId = ref("");
    const getAllOrderIds = ref([]);
    const checkedOrderJobTicket = ref([]);
    const store = useStore();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchData = ref(store.getters.getOrderSearch(moduleName.value));
    const searchParams = reactive({
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      page_type: "order_listing",
      partner: searchData.value.searchByTradingPartner,
      search: searchData.value.search,
      sortBy:"id",
      sortDesc:"desc",
      searchByOrderDate: searchData.value.searchByOrderDate,
      searchByOrderDueDate: searchData.value.searchByOrderDueDate,
      orderType: searchData.value.searchByOrderType,
      searchByOrderStatus: searchData.value.searchByOrderStatus,
      isPendingOrder:0
    })

    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ]
    const permission = reactive({
      isPullAllowed: false,
      isViewAllowed: false,
      isViewOrderProductAllowed: false,
      isDownloadJobTicketAllowed: false,
      isImportOrderAllowed: false,
      isImportOrderHistoryAllowed: false,
      isDownloadOrderInvoiceAllowed: false,
      isOrderWatchlistAllowed: false
    })

    const tableHeader = reactive([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        visible: true,
      },
      {
        name: ordersConstants.PARTNER,
        key: 'partner',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.ID,
        key: 'id',
        visible: true,
      },
      {
        name: ordersConstants.INVOICE_NO,
        key: 'invoice_number',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_TYPE,
        key: 'order_type',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.PARTNER_ORDER_ID,
        key: 'order_id',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.CUSTOMER_DETAILS,
        key: 'customer_details',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_DATE,
        key: 'order_date',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_DUE_DATE,
        key: 'order_due_date',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.PRODUCT_ITEMS,
        key: 'order_products_count',
        visible: true,
        sortable: false,
      },
      {
        name: ordersConstants.PAYMENT_AMOUNT,
        key: 'total_amount',
        visible: false,
        sortable: false
      },
      {
        name: ordersConstants.SHIPPING_AND_METHOD,
        key: 'payment_shipping',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_STATUS,
        key: 'order_status',
        visible: false,
        sortable: false,
      },
      {
        name: ordersConstants.ORDER_WATCHLIST,
        key: 'order_watchlist',
        visible: true,
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        visible: true,
        sortable: false,
      },
    ]);
    const visibleFields = computed(() => {
        return tableHeader.filter(header => header.visible)
    })

    //Download Job Ticket
    const downloadJobTicket = (id,tradingPartnerId,customerName) => {
      axios({
          url: 'template-management/download-job-ticket',
          method: 'POST',
          data: {
            orderId: id,
            tradingPartnerId: tradingPartnerId
          },
          responseType: 'arraybuffer',
      }).then((response) => {
          let blob = new Blob([response.data], {
              type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          if(customerName != undefined){
            link.download = 'jobticket_'+id+'_'+(customerName.replace(/ /g, ""))+'.pdf'
          }else{
            link.download = 'jobticket_'+id+'_'+Date.now()+'.pdf'
          }
          link.click()
      })
      .catch((error) => {
        let response = error;
          if (response != "") {
            notificationFire('Job ticket content is not set', "error");
          }
      });
      return false;
    }

    //Download Order Invoice
    const downloadOrderInvoice = (id,tradingPartnerId,customerName) => {
      axios({
          url: 'template-management/download-order-invoice',
          method: 'POST',
          data: {
            orderId: id,
            tradingPartnerId: tradingPartnerId
          },
          responseType: 'arraybuffer',
      }).then((response) => {
          let blob = new Blob([response.data], {
              type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          if(customerName != undefined){
            link.download = 'order_invoice_'+id+'_'+(customerName.replace(/ /g, ""))+'.pdf'
          }else{
            link.download = 'order_invoice_'+id+'_'+Date.now()+'.pdf'
          }
          link.click()
      })
      .catch((error) => {
        let response = error;
          if (response != "") {
            notificationFire('Invoice content is not set', "error");
          }
      });
      return false;
    }

    const assignViewOrderProductModalId = (id) => {
      orderId.value.getOrderProducts(id);
    }

    const submitFilters = async (filters) => {
      
      if (!tableHeader) return
        let filterData = {
          orderFilters: tableHeader.map(({key, visible}) => ({[key]: visible})),
        };
        ApiService.post('users/save-filters', filterData)
        .then((response) => {
          if (response.data) {
            let status = response.status;
            let message = response.data.message;
            if (status == 200 || status == 201) {
              notificationFire(message,'success');
            } else {
              notificationFire(message,'error');
            }
          }
        })
        .catch((error) => {
          loading.value = false;
          loadingback.value = false;
          let message = error.message;
          notificationFire(message,'error');
        });
    }
    
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    }

    const handleSortChange = (obj) => {
      searchParams.sortBy = obj.columnName;
      searchParams.sortDesc = obj.order;
      getOrders();
    }
    
    const handleCurrentChange = async(number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      await getOrders();
      MenuComponent.reinitialization();
    }

    const openDetailPage = (order_id) => {
      router.push('/orders/'+order_id)
    }

    const redirectToPendingOrder = (order_id) => {
      router.push('/pending-orders/'+order_id)
    }

    //Get all orders
    const getOrders = async () => {
      loading.value = true;
      await ApiService.query("orders", { params: searchParams })
      .then(({ data }) => {
        if(data.data.filter)
        {
          var filterData = data.data.filter;
          tableHeader.forEach((options, headerKey) => {
            let key = options.key;
            if (filterData[key] !== undefined) {
              tableHeader[headerKey].visible = filterData[key];
            }
          });
        }
        if (data.data.data.length) {
          tableData.value.splice(0, tableData.value.length, ... data.data.data);
          total.value = data.data.total;
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
        }
        else
        {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
        tableData.value.forEach((value) => {
          getAllOrderIds.value.push(value['id']);
        });
        setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tableData.value = [];
        total.value = 0;
        loading.value = false;
      });
    }

    //Get all active trading partners
    const getTradingPartners = async () => {
      loading.value = true;
      await ApiService.query("get-active-trading-partner")
      .then(({ data }) => {
        if (data.data) {
          tradingPartners.value = data.data;

          isStoreInfoTradingPartner.value = Object.values(data.data).every(value => {
            if (value.store_info === null) {
              return true;
            }
            return false;
          });
        } else {
          tradingPartners.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        tradingPartners.value = [];
        loading.value = false;
      });
    }

    //Get all active statuses
    const getActiveStatusForOrders = async () => {
      loading.value = true;
      await ApiService.query("get-order-status")
      .then(({ data }) => {
        if (data.data) {
          orderStatus.value = data.data;
        } else {
          orderStatus.value = [];
        }
        loading.value = false;
      })
      .catch((error) => {
        //Toast the error here
        orderStatus.value = [];
        loading.value = false;
      });
    }

    //Pull orders from trading partners
    const pullOrders = async (tradingPartnerId) => {
        let url = 'pull-orders';
        let index = 0;
        if (tradingPartnerId == 'all') {
          url = 'pull-all-orders';
          pullAllOrderLoading.value = true; 
        } else {
          index = tradingPartners.value.findIndex((i) => i.trading_partner_id === tradingPartnerId);
          tradingPartners.value[index].loading = true;
        }
        
      await ApiService.post(url, {tradingPartnerId: tradingPartnerId})
      .then(({ data }) => {
          let status = data.status;
          let message = data.message;
          if (status) {
            getPendingOrdersCount();
            ElNotification({
              message: message,
              type: 'success',
            });
            getOrders();
          }
          if (tradingPartnerId == 'all') {
            pullAllOrderLoading.value = false;
          } else {
            tradingPartners.value[index].loading = false;
          }
      })
      .catch((error) => {
        if (tradingPartnerId == 'all') {
            pullAllOrderLoading.value = false;
          } else {
            tradingPartners.value[index].loading = false;
          } 
        let response = error.response;
        if (response != "") {
            let message = response.data.message;
            ElNotification({
            message: message,
            type: 'error',
          });
        } else {
          let message = error.message;
          ElNotification({
            message: message,
            type: 'error',
          });
        }
      });
    }

    const addOrderWatch = async(order_id, type, index, order_watchlist_id='') => {
      //return false;
      await ApiService.post("/job-board/add-order-watchlist", {
        order_id: order_id,
        type: type,
        order_watchlist_id: order_watchlist_id
      })
      .then((response) => {
        if(type == 'delete'){
          tableData.value[index].order_watchlist = null;
        }else{
          tableData.value[index].order_watchlist = response.data.data;
        }
        notificationFire(response.data.message, "success");
      })
      .catch((error) => {
        let response = error.response;
        if (response != "") {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    //Reset search value
    const resetSearch = async() => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
      }
      searchParams.partner = "";
      searchParams.searchByOrderDate = "";
      searchParams.searchByOrderDueDate = "";
      searchParams.orderType = "";
      searchParams.searchByOrderStatus = "";
      rememberSearch();
      await getOrders();
      MenuComponent.reinitialization();
    };

    //Order search event function
    const handleSearch = () => {
      rememberSearch();
      handleCurrentChange(1);
    }

    //Download batch job ticket functionality
    const downloadBatchJoBTicket = (val) => {
      let orderIds = [];
      if (val == 'selected') {
        if(checkedOrderJobTicket.value.length == 0) {
          notificationFire('Please select at least one order to download job ticket.', "error");
          return false;
        }
        orderIds = checkedOrderJobTicket.value;
      } else if (val == 'all') {
        orderIds = getAllOrderIds.value;
      }
      loading.value = true;
      axios({
          url: 'template-management/download-batch-job-ticket',
          method: 'POST',
          data: {
            orderIds: orderIds
          },
          responseType: 'arraybuffer',
      }).then((response) => {
          let blob = new Blob([response.data], {
              type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'jobticket_'+Date.now()+'.pdf'
          link.click()
          loading.value = false;
      })
      .catch((error) => {
        let response = error;
        if (response != "") {
          notificationFire('Job ticket content is not set', "error");
        }
        loading.value = false;
      });
      return false;      
    }

    const setDownloadJobTicket = (val,orderId) => {
      if(val){
        checkedOrderJobTicket.value.push(orderId);
      }else{
        checkedOrderJobTicket.value.splice(checkedOrderJobTicket.value.indexOf(orderId),1);
      }
    }

    const setImage = (image) => {
        if (image == null) {
            return '/media/avatars/blank.png';
        }
      return process.env.VUE_APP_API_URL + "../../../partner_image/" + image;
    };

    //Set breadcrumbs and get the orders
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("pullOrder") || role == "Super Admin")
        permission.isPullAllowed = true;
      if (per.includes("viewOrder") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("viewOrderProduct") || role == "Super Admin")
        permission.isViewOrderProductAllowed = true;
      if (per.includes("downloadTicketOrder") || role == "Super Admin")
        permission.isDownloadJobTicketAllowed = true;
      if (per.includes("importOrder") || role == "Super Admin")
        permission.isImportOrderAllowed = true;
      if (per.includes("importOrderHistory") || role == "Super Admin")
        permission.isImportOrderHistoryAllowed = true;
      if (per.includes("downloadOrderInvoice") || role == "Super Admin")
        permission.isDownloadOrderInvoiceAllowed = true;
      if (per.includes("orderWatchlist") || role == "Super Admin")
        permission.isOrderWatchlistAllowed = true;
      getTradingPartners();
      getActiveStatusForOrders();
      await getOrders();
      MenuComponent.reinitialization();
    })
    
    const rememberSearch = () => {
      let actionParams = {
          "search": searchParams.search,
          "searchByTradingPartner": searchParams.partner,
          "searchByOrderType": searchParams.orderType,
          "searchByOrderDate": searchParams.searchByOrderDate,
          "searchByOrderDueDate": searchParams.searchByOrderDueDate,
          "searchByOrderStatus": searchParams.searchByOrderStatus
      }
      store.dispatch(Actions.ORDER_SEARCH, {'module':moduleName.value,'params':actionParams});
    };

    const getPendingOrdersCount = async () => {
      await ApiService.post("/pending-orders-count", {})
      .then(({ data }) => {
        if (data.pendingOrderProduct  != undefined) {
          emitEvent('counterUpdate',{"pendingOrders":data.pendingOrders})
        }
        if (data.pendingOrderProduct  != undefined) {
          emitEvent('counterUpdate',{"pendingOrderProducts":data.pendingOrderProduct})
        }
      })
      .catch((error) => {
        console.log(error);
        //Toast the error here
      });
    }

    return {
      tableData,
      assignViewOrderProductModalId,
      formatPrice,
      formatDate,
      formatDateTime,
      getOrders,
      total,
      handleSizeChange,
      handleCurrentChange,
      handleSortChange,
      loading,
      searchParams,
      openDetailPage,
      pullOrders,
      visibleFields,
      submitFilters,
      downloadJobTicket,
      tradingPartners,
      getTradingPartners,
      tableHeader,
      generalConstants,
      noDataFound,
      updateProps,
      resetSearch,
      ordersConstants,
      handleSearch,
      orderId,
      shortcuts,
      permission,
      redirectToPendingOrder,
      downloadBatchJoBTicket,
      setDownloadJobTicket,
      downloadOrderInvoice,
      addOrderWatch,
      pullAllOrderLoading,
      isStoreInfoTradingPartner,
      getPendingOrdersCount,
      setImage,
      getActiveStatusForOrders,
      orderStatus
    }
  },
};

</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.el-link {
  justify-content: left;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.btn.btn-purple{
  background-color: #8618cbbf;
  border-color: #8618cbbf;
  color:#ffffff;
}
.btn.btn-purple i{color:#ffffff;}
.btn.btn-purple svg path{fill:#ffffff !important;}
.btn.btn-purple:hover,.btn.btn-purple:focus,.btn.btn-purple:active{
  background-color: #6911a1bf;
  border-color: #6911a1bf;
}
.menu-state-bg-light-purple .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-purple .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #f5e7ff96;
  color: #8618cbbf;
}
.el-popper.is-dark {
  text-align: center !important;
}
</style>