<template>
  <!-- Order Products Modal -->
  <div
    class="modal fade modal-xl modal-fullscreen"
    id="order_products_modal"
    ref="modalRef"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog mw-1400px">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <p class="mt-2 mb-0">
              {{ ordersConstants.ORDER_ITEMS }} (<a
                href="javascript:void(0)"
                @click.prevent="openDetailPage(orderTableId)" 
                data-bs-dismiss="modal"
                >#{{ orderTableId }}</a
              >)
            </p>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <Datatable
            :table-data="tableData"
            :table-header="tableHeader"
            :empty-table-text="noDataFound"
            :loading="loading"
            :enable-items-per-page-dropdown="false"
            ref="updateProps"
          >
            <template v-slot:cell-image="{ row: data }">
              <el-image
                style="width: 100px; height: 100px"
                :src="data.product_files[0]"
                :preview-src-list="data.product_files"
                :initial-index="0"
                fit="cover"
              >
                <template #error>
                  <div class="image-slot">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="'/media/no-img.jpg'"
                      fit="cover"
                    />
                  </div>
                </template>
              </el-image>
            </template>
            <template v-slot:cell-productDetails="{ row: data }">
              <div class="d-flex align-items-center">
                <div>
                  <a
                    href="javascript:void(0)"
                    class="fw-bolder text-gray-600 text-hover-primary"
                    @click.prevent="openDetailPage(data.order_id, data.product_id)"
                    >{{ data.product_name }}</a
                  >
                  <div class="fs-7 text-muted" v-if="!!data.product_sku">
                    <span class="text-gray-800">{{ generalConstants.SKU }}:</span> {{ data.product_sku }}
                    <template v-if="!!data.separated_product_sku"><br>
                    <span class="text-gray-800">{{ constants.product.PRODUCT_SKU }}: </span>
                    <el-tooltip class="box-item" effect="dark" :content="data.sku_status == 'Matched' ? 'Mapped with: ' + data.reference_sku_code : data. sku_status == 'Unmatched' ? 'SKU not found in system!' : 'SKU rejected'" placement="top" >
                      <span :class="data.sku_status == 'Matched' ? 'badge badge-light-success' : data.sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ data.separated_product_sku }}</span>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" :content="data.sku_status == 'Matched' ? 'Mapped with: ' + data.reference_sku_code : data.sku_status == 'Unmatched' ? 'SKU not found in system!' : 'SKU rejected'" placement="top" raw-content>
                      <i class="bi bi-question-circle-fill"></i>
                    </el-tooltip>
                    </template>
                    <template v-if="!!data.separated_art_sku"><br>
                      {{ constants.product.ART_SKU }}: 
                      <el-tooltip class="box-item" effect="dark" :content="data.art_sku_status == 'Matched' ? 'Mapped with: ' + data.reference_art_sku_code : data. sku_status == 'Unmatched' ? 'Art SKU not found in system!' : 'SKU rejected'" placement="top" >
                      <span :class="data.art_sku_status == 'Matched' ? 'badge badge-light-success' : data.art_sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ data.separated_art_sku }}</span>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" :content="data.art_sku_status == 'Matched' ? 'Mapped with: ' + data.reference_art_sku_code : data.art_sku_status == 'Unmatched' ? 'Art SKU not found in system!' : 'SKU rejected'" placement="top" raw-content>
                      <i class="bi bi-question-circle-fill"></i>
                    </el-tooltip>
                    </template>
                  </div>
                  <div class="fs-7 text-muted" v-if="!!data.product_weight">
                    {{ ordersConstants.WEIGHT }}: {{ data.product_weight }}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:cell-additionalInfo="{ row: data }">
              <div v-if="data.product_additional_info.length > 0">
                <div
                  v-for="(optionsData, i) in data.product_additional_info"
                  :key="i"
                >
                  <dl class="row mb-0">
                    <dt class="col-sm-6 text-right pl-0 mb-0 pr-0">
                      <span v-html="optionsData.option_title"></span> :
                    </dt>
                    <dd class="col-sm-6 mb-0">
                      <span v-html="optionsData.option_value"></span>
                    </dd>
                  </dl>
                </div>
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:cell-shippingTypeAndMethods="{ row: data }">
              <span v-if="data.shipping_method || data.shipping_type">
                <span>{{ data.shipping_method }} </span> <span>{{ data.shipping_type }}</span>
              </span>
              <span v-else>-</span>
            </template>
            <template v-slot:cell-status="{ row: data }">
             <span class="badge" :class="`badge badge-light-${data.order_product_status_badge}`">{{
                data.order_product_status_title
              }}</span
              >
            </template>
            <template v-slot:cell-quantity="{ row: data }">
              <span class="badge bg-primary">{{ data.product_quantity }}</span>
            </template>
            <template v-slot:cell-price="{ row: data }">
              {{ formatPrice(data.product_price) }}
            </template>
            <template v-slot:cell-actions="{ row: data }">
              <p v-if="permission.isViewAllowed || permission.isDownloadShipmentLabelAllowed || permission.isDownloadJobTicketAllowed || permission.isDownloadPackagingSlipAllowed">
              <el-tooltip class="box-item" effect="dark" :content="ordersConstants.VIEWORDERDETAILS" placement="top" v-if="permission.isViewAllowed">
                <button type="button" data-bs-dismiss="modal" @click.prevent="openDetailPage(data.order_id, data.product_id)" class="btn btn-icon btn-light btn-sm me-2">
                  <i class="bi bi-eye"></i>
                </button>
              </el-tooltip>
              <!-- <el-tooltip class="box-item" effect="dark" :content="ordersConstants.DOWNLOAD_SHIPMENT_LABEL" placement="top" v-if="permission.isDownloadShipmentLabelAllowed && data.is_pending == 1">
                <button type="button" data-bs-dismiss="modal" @click.prevent="manageShipmentLabel(data, true)" class="btn btn-icon btn-light btn-sm me-2">
                  <i class="bi bi-download"></i>
                </button>
              </el-tooltip> -->
              <el-tooltip class="box-item" effect="dark" :content="ordersConstants.DOWNLOAD_PACKAGING_SLIP" placement="top" v-if="permission.isDownloadPackagingSlipAllowed && data.is_pending == 1">
                <button type="button" data-bs-dismiss="modal" @click.prevent="downloadPackagingSlip(data.product_id)" class="btn btn-icon btn-light btn-sm me-2">
                  <i class="bi bi-download"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="box-item" effect="dark" :content="ordersConstants.DOWNLOAD_JOB_TICKET" placement="top" v-if="permission.isDownloadJobTicketAllowed && data.is_pending == 1">
                <button type="button" data-bs-dismiss="modal" @click.prevent="downloadJobTicket(data.product_id)" class="btn btn-icon btn-light btn-sm me-2">
                  <i class="bi bi-download"></i>
                </button>
              </el-tooltip>
              </p>
              <p v-else>
                -
              </p>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <div v-if="permission.isDownloadShipmentLabelAllowed">
      <GenerateOrderShipmentModal
        @getDetails="getOrderProducts"
        ref="prepareshipmentData"
      ></GenerateOrderShipmentModal>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRouter } from "vue-router";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import {
  createOrderPoductOptions,
  createOrderPoductFiles
} from "@/composable/order.js";
import { formatDate, formatPrice } from "@/core/helpers/common";
import GenerateOrderShipmentModal from "@/components/modals/forms/GenerateOrderShipmentModal.vue";
import axios from "axios";

export default {
  name: "viewOrderProducts",
  components: {
    Datatable,
    GenerateOrderShipmentModal
  },
  setup() {
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const constants = globalConstant;
    const noDataFound = ref(generalConstants.LOADINIG);
    const tableData = ref([]);
    const loading = ref(false);
    const orderTableId = ref(0);
    const isPendingOrder = ref(false);
    const router = useRouter();
    const updateProps = ref(null);
    const prepareshipmentData = ref(null);
    const searchParams = reactive({
      recordsPerPage: 10,
      pageNub: 1,
    });
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
        alignment: 'text-center'
      },
      {
        name: generalConstants.IMAGE,
        key: "image",
        sortable: false,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: ordersConstants.PRODUCT_DETAILS,
        key: "productDetails",
        sortable: false,
        width: "w-300px",
      },
      {
        name: ordersConstants.ADDITIONAL_INFORMATION,
        key: "additionalInfo",
        width: "w-200px",
        sortable: false,
      },
      {
        name: "Shipping Type & Method",
        key: "shippingTypeAndMethods",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        sortable: false,
      },
      {
        name: generalConstants.QUANTITY,
        key: "quantity",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.PRICE,
        key: "price",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        sortable: false,
      },
    ]);

    const openDetailPage = (orderId, orderProductId) => {
      if(isPendingOrder.value == true){
        router.push('/pending-order-products/product/'+orderId+'/'+orderProductId)
      }else{
        router.push('/order-products/product/'+orderId+'/'+orderProductId)
      }
    };

    const permission = reactive({
      isViewAllowed: false,
      isDownloadShipmentLabelAllowed: false,
      isDownloadJobTicketAllowed: false,
      isDownloadPackagingSlipAllowed: false
    });

    const getOrderProducts = async (orderId, isPending) => {
      loading.value = true;
      const url = "orderproducts";
      isPendingOrder.value = (isPending) ? true : false;
      orderTableId.value = orderId;
      await ApiService.query(url, { params: { order_id: orderId,isPendingOrder: (isPending) ? 1 : 0} })
        .then(({ data }) => {
          if (data.data && data.data.length) {
            tableData.value = data.data;
            
            if (tableData.value.length > 0) {
              tableData.value.forEach((value) => {
                value["product_additional_info"] =
                  createOrderPoductOptions(value)["productAdditionalInfo"];
                value["product_files"] =
                  createOrderPoductFiles(value)["productFilesInfo"];
              });
            }
          } else {
            updateProps.value.updateProps(0, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          loading.value = false;
        })
        .catch((error) => {
          tableData.value = [];
          loading.value = false;
          let message = "";
          if (typeof error.response !== "undefined") {
            message = error.response.data.message;
          } else if (typeof error.message !== "undefined") {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
    };

    //Prepare Shipment Label form data
    const manageShipmentLabel = async(productData, isDownload) => {
      prepareshipmentData.value.prepareshipmentData(productData, isDownload);
    }

    //Download job ticket functionality
    const downloadJobTicket = (orderProductId) => {
      let individualOrderProductId = [];
      individualOrderProductId.push(orderProductId);
      axios({
          url: 'template-management/download-order-product-job-ticket',
          method: 'POST',
          data: {
            orderProductIds: individualOrderProductId
          },
          responseType: 'arraybuffer',
      }).then((response) => {
          let blob = new Blob([response.data], {
              type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          link.click()
      })
      .catch((error) => {
        let response = error;
        if (response != "") {
          notificationFire('No Order Product found', "error");
        }
      });
    }

    //Download Packaging Slip
    const downloadPackagingSlip = (orderProductId) => {
      let individualOrderProductId = [];
      individualOrderProductId.push(orderProductId);
      axios({
          url: 'template-management/download-order-product-packaging-slip',
          method: 'POST',
          data: {
            orderProductIds: individualOrderProductId
          },
          responseType: 'arraybuffer',
      }).then((response) => {
          let blob = new Blob([response.data], {
              type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          link.click()
      })
      .catch((error) => {
        let response = error;
        if (response != "") {
          notificationFire('No Order Product found', "error");
        }
      });
    };

    //Set breadcrumbs and get the orders
    onMounted(async () => {
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("viewPendingOrder") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("downloadShipmentLabel") || role == "Super Admin")
        permission.isDownloadShipmentLabelAllowed = true;
      if (per.includes("downloadTicketOrder") || role == "Super Admin")
        permission.isDownloadJobTicketAllowed = true;
      if (per.includes("downloadpackagingslip") || role == "Super Admin")
        permission.isDownloadPackagingSlipAllowed = true;
    })

    return {
      noDataFound,
      orderTableId,
      getOrderProducts,
      formatDate,
      tableData,
      tableHeader,
      generalConstants,
      ordersConstants,
      openDetailPage,
      formatPrice,
      isPendingOrder,
      loading,
      updateProps,
      permission,
      constants,
      manageShipmentLabel,
      prepareshipmentData,
      downloadJobTicket,
      downloadPackagingSlip
    };
  },
};
</script>
<style lang="scss">
.mw-1400px{
  max-width: 1400px;
}
</style>